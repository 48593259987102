import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../logo";
import ColorfulLogo from "../logoColorful";

const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false);

  const status = localStorage.getItem("status") == "login" ? true : false;

  return (
    <Fragment>
      <div
        className={
          "wrapper__side-nav-mobile d-flex d-lg-none " +
          (toogleMenu ? "active" : "")
        }
      >
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center mb-4">
              <img
                src="./../images/close (1).png"
                onClick={() => setToogleMenu(!toogleMenu)}
                className="pointer"
                alt=""
              />
              <NavLink
                to="/"
                exact
                class={
                  "navbar-brand font__size--20 text__20-1024 bold " +
                  (props.type == "light" ? "color__black" : "color__white")
                }
              >
                <div className="d-flex align-items-center">
                  <img src="./../images/main-log.svg" alt="Treeland" />
                </div>
              </NavLink>
            </div>
            <div className="menu">
              <NavLink
                to="/#about-su"
                className="semi-bold font__size--16 text__16-1024 color__white"
              >
                About us
              </NavLink>
              <hr />
              <NavLink
                to="/#solution"
                className="semi-bold font__size--16 text__16-1024 color__white"
              >
                Solution
              </NavLink>
              <hr />
              <NavLink
                to="/#contact-us"
                className="semi-bold font__size--16 text__16-1024 color__white"
              >
                Contact us
              </NavLink>
              {/*
              <hr />
              <NavLink
                to="/about-us"
                className="semi-bold font__size--16 text__16-1024 color__white"
              >
                About Us
              </NavLink>
              <hr />
              <NavLink
                to="/faq"
                className="semi-bold font__size--16 text__16-1024 color__white"
              >
                FAQ
              </NavLink>
              <hr />
            */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg__wrap-menu d-lg-none"
        onClick={() => setToogleMenu(!toogleMenu)}
      ></div>
      <nav
        className="navbar navbar-expand-lg wrapper__navbar position-relative z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <div className="d-flex align-items-center">
            {/*
            <div
              onClick={() => setToogleMenu(!toogleMenu)}
              class="nav__button d-lg-none position-relative mr-1 mr-sm-3 flex-shrink-0"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            */}
            <NavLink
              to="/"
              exact
              class={
                "navbar-brand font__size--20 text__20-1024 bold " +
                (props.type == "light" ? "color__black" : "color__white")
              }
            >
              <div className="d-flex align-items-center">
                {props.type == "light" ? <ColorfulLogo /> : <Logo />}
              </div>
            </NavLink>
          </div>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-lg-5"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <NavLink
                  to="/#about-us"
                  class={
                    "nav-link semi-bold font__size--16 text__16-1024 " +
                    (props.type == "light" ? "color__black" : "color__white")
                  }
                >
                  About us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/#solution"
                  exact
                  class={
                    "nav-link semi-bold font__size--16 text__16-1024 " +
                    (props.type == "light" ? "color__black" : "color__white")
                  }
                >
                  Solution
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/#contact-us"
                  class={
                    "nav-link semi-bold font__size--16 text__16-1024 " +
                    (props.type == "light" ? "color__black" : "color__white")
                  }
                >
                  Contact us
                </NavLink>
              </li>
              {/*
              <li className="nav-item">
                <NavLink
                  to="/blog"
                  class={
                    "nav-link semi-bold font__size--16 text__16-1024 " +
                    (props.type == "light" ? "color__black" : "color__white")
                  }
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about-us"
                  class={
                    "nav-link semi-bold font__size--16 text__16-1024 " +
                    (props.type == "light" ? "color__black" : "color__white")
                  }
                >
                  About us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/faq"
                  class={
                    "nav-link semi-bold font__size--16 text__16-1024 " +
                    (props.type == "light" ? "color__black" : "color__white")
                  }
                >
                  FAQ
                </NavLink>
              </li>
          */}
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
