import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
const DetailBlog = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <Navbar type="light" />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-8 mb-4 mb-md-0">
                <h2 className="bold font__size--40 text__40-1024 text__40-md text__40-mm mb-3">
                  Procurement professionals: how to avoid violating compliance
                </h2>
                <div className="d-flex align-items-center">
                  <div className="d-inline-block">
                    <div className="wrapper__point wrap">
                      <div className="dot"></div>
                      <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                        For Freelance
                      </h5>
                    </div>
                  </div>
                  <p className="semi-bold font__size--14 text__14-1024 color__gray-2 ml-3 mb-0">
                    Juni 27, 2022
                  </p>
                </div>

                <div className="mt-4">
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    Procurement can be challenging, especially with several
                    roadblocks along the supply chain. Even still, the most
                    significant liability could easily be the talent shortage.
                    Following the pandemic, procuring top talent in a shifting
                    economic landscape deserves more spotlight, especially amid
                    a turbulent fiscal forecast.
                  </p>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    The changes businesses make now will likely decide whether
                    or not they survive. Because managing procurement risks
                    starts with being aware of them, streamlining procedures and
                    taking advantage of technology — the only real solution to
                    getting ahead of compliance issues.
                  </p>

                  <div className="wrapper__left-line mb-4">
                    <h5 className="bold font__size--20 text__20-1024 color__gray-2 mb-4">
                      “Inflation will put more pressure on chief procurement
                      officers to manage their departments strictly within
                      budget.”{" "}
                    </h5>
                    <h5 className="bold font__size--20 text__20-1024 color__gray-2 mb-0">
                      - Ardent Partners Annual Research Report
                    </h5>
                  </div>

                  <img
                    src="./../images/dsgdsgg.png"
                    className="images__detail mb-4"
                    alt=""
                  />
                  <h5 className="bold font__size--18 text__18-1024 mb-4">
                    The #1 Compliance Pitfall: Worker Classification
                  </h5>

                  <p className="normal font__size--18 text__18-1024 mb-4">
                    A common freelance hiring issue originates from a poor chain
                    of custody around worker classification data and worker
                    classification itself. Without legal worker classification,
                    your organization is in danger of costly litigation. If you
                    should get audited, could you stand by your worker
                    classification procedures?{" "}
                  </p>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    Do you handle worker classification in-house, or is it
                    outsourced to your managed service provider (MSP), who may
                    outsource it to a vendor management solution (VMS)? Because
                    many businesses are redistributing the responsibility of
                    worker classification to a third party or still have paper
                    processes in place, which is awfully precarious and, dare I
                    say, archaic.{" "}
                  </p>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    Remember, the third party you employ to carry out worker
                    classification won't be in the hot seat, so to speak, if an
                    audit occurs and the data isn't there. Tech solutions like
                    Worksome indemnify enterprises using its state-of-the-art
                    tool to classify workers. The Worksome Classify system can
                    perform worker classification in minutes, not weeks or
                    months, with 100% compliance guaranteed.
                  </p>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    Do you want to optimize processes to save time, make your
                    budget go further and innovate while staying compliant? Book
                    a free Worksome demo today!
                  </p>
                </div>
              </div>
              <div className="col-md-4 pl-md-5">
                <h4 className="bold font__size--24 text__24-1024 mb-4">
                  Latest articles
                </h4>

                <div>
                  <div className="d-inline-block mb-3">
                    <div className="wrapper__point wrap">
                      <div className="dot"></div>
                      <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                        For Freelance
                      </h5>
                    </div>
                  </div>
                  <h5 className="bold font__size--24 text__24-1024 mb-2">
                    Procurement professionals: how to avoid violating compliance
                  </h5>
                  <p className="semi-bold font__size--14 text__14-1024 color__gray-2 mb-0">
                    Juni 27, 2022
                  </p>
                </div>
                <hr className="my-4" />
                <div>
                  <div className="d-inline-block mb-3">
                    <div className="wrapper__point wrap">
                      <div className="dot"></div>
                      <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                        For Freelance
                      </h5>
                    </div>
                  </div>
                  <h5 className="bold font__size--24 text__24-1024 mb-2">
                    Global Freelancer Survey Report 2022
                  </h5>
                  <p className="semi-bold font__size--14 text__14-1024 color__gray-2 mb-0">
                    Juni 27, 2022
                  </p>
                </div>
                <hr className="my-4" />
                <div>
                  <div className="d-inline-block mb-3">
                    <div className="wrapper__point wrap">
                      <div className="dot"></div>
                      <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                        For Freelance
                      </h5>
                    </div>
                  </div>
                  <h5 className="bold font__size--24 text__24-1024 mb-2">
                    Business entities for freelancers
                  </h5>
                  <p className="semi-bold font__size--14 text__14-1024 color__gray-2 mb-0">
                    Juni 27, 2022
                  </p>
                </div>
                <hr className="my-4" />

                <h4 className="bold font__size--24 text__24-1024 pt-4 mb-4">
                  Categories
                </h4>
                <a href="#!" className="w-100 color__black mb-3 d-inline-block">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="semi-bold font__size--20 text__20-1024">
                      Management
                    </span>
                    <img src="./../images/arrow-right.png" alt="" />
                  </div>
                </a>
                <a href="#!" className="w-100 color__black mb-3 d-inline-block">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="semi-bold font__size--20 text__20-1024">
                      Compliane
                    </span>
                    <img src="./../images/arrow-right.png" alt="" />
                  </div>
                </a>
                <a href="#!" className="w-100 color__black mb-3 d-inline-block">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="semi-bold font__size--20 text__20-1024">
                      Strategy
                    </span>
                    <img src="./../images/arrow-right.png" alt="" />
                  </div>
                </a>
                <a href="#!" className="w-100 color__black mb-3 d-inline-block">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="semi-bold font__size--20 text__20-1024">
                      Sourcing
                    </span>
                    <img src="./../images/arrow-right.png" alt="" />
                  </div>
                </a>
                <a href="#!" className="w-100 color__black mb-3 d-inline-block">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="semi-bold font__size--20 text__20-1024">
                      For Freelance
                    </span>
                    <img src="./../images/arrow-right.png" alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default DetailBlog;
