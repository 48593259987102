import React, { useState, Fragment, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Pricing from "../../component/Other/Pricing";
const Index = (props) => {
  const [topBg, setTopBg] = useState('')

  useEffect(() => {
    const img = new Image()
    img.onload = function() {
      setTopBg(img.src)
    }
    img.src = '/images/top-bg.jpeg'
  }, [])

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__black top-bg" 
          {...(topBg && {style: {backgroundImage: `url(${topBg})`}})}
        >
          <Navbar />
          <section>
            <div className="container top-content">
              <div className="row">
                <div className="col-lg-6 text-center text-sm-left">
                  <h1 className="bold font__size--48 text__48-1024 text__48-md text__48-sm color__white mt__5 md-0">
                    Partner with Treeland for online business success
                  </h1>

                  <p className="normal font__size--16 text__16-1024 color__white my-4 color__gray-1">
                    Grow your business with our customized support and
                    cutting-edge technology
                  </p>

                  <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                    <a
                      href="https://apps.shopify.com/partners/treelandapp"
                      className="wrapper__wrap-mm-100 btn btn__green mb-4 mb-sm-0 font__size--16 text__16-1024 semi-bold color__black shadow rounded__50"
                    >
                      Get started
                    </a>
                    {/*
                    <a href="#!" className="wrapper__wrap-mm-100 btn btn__outlined--green font__size--16 text__16-1024 semi-bold color__white h__black no__opacity shadow rounded__50 ml-sm-3">Create a free account</a>
                  */}
                  </div>
                </div>
                <div className="col-md-6 d-none d-lg-block"></div>
              </div>
            </div>
          </section>
        </div>
        <section className="bg-gray" id="about-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-5 text-center text-lg-left my-lg-auto">
                <img src="./../images/treeland.png" alt="" />
              </div>
              <div className="col-lg-6 text-center text-lg-left my-auto">
                <div className="wrapper__point justify-content-center justify-content-lg-start mb-3">
                  <div className="dot"></div>
                  <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                    Intro
                  </h5>
                </div>
                <h1 className="bold font__size--40 text__40-1024 text__40-md text__40-mm ">
                  About us
                </h1>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">
                  We're thrilled to provide our SaaS solution to you, designed
                  to streamline your operations and increase sales. As a trusted
                  partner for businesses looking to grow their online presence,
                  we deliver innovative, efficient solutions that help clients
                  find e-commerce success through personalized support and
                  expert guidance.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="solution">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center text-lg-left order-lg-1 order-12 my-auto">
                <div className="wrapper__point justify-content-center justify-content-lg-start mb-3">
                  <div className="dot"></div>
                  <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                    Service
                  </h5>
                </div>
                <h1 className="bold font__size--40 text__40-1024 text__40-md text__40-mm ">
                  Extensive Scope
                </h1>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">
                  Treeland is dedicated to helping you build and develop online
                  businesses. We can provide a full range of standardized
                  software services, such as digital marketing, order
                  management, customer service, and inventory management.
                </p>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">
                  Additionally, we offer professional consulting services to
                  provide custom IT solutions for our customers, thus enabling
                  you to create highly customized online stores with ease.
                </p>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 mb-5 text-center text-lg-left my-lg-auto">
                <img src="./../images/content-1.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-5 text-center text-lg-left my-lg-auto">
                <img src="./../images/content-2.png" alt="" />
              </div>
              <div className="col-lg-6 text-center text-lg-left my-auto">
                <div className="wrapper__point justify-content-center justify-content-lg-start mb-3">
                  <div className="dot"></div>
                  <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                    Experience
                  </h5>
                </div>
                <h1 className="bold font__size--40 text__40-1024 text__40-md text__40-mm ">
                  Professional Team
                </h1>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">
                  Our team consists of dedicated professionals who work on
                  technology, design, and operations. We have the knowledge and
                  experience to build various types of Shopify apps and can
                  provide effective solutions that are tailored to the needs of
                  our clients.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center text-lg-left order-lg-1 order-12 my-auto">
                <div className="wrapper__point justify-content-center justify-content-lg-start mb-3">
                  <div className="dot"></div>
                  <h5 className="mb-0 ml-2 color__blue semi-bold font__size--16 text__16-1024">
                    Vision
                  </h5>
                </div>
                <h1 className="bold font__size--40 text__40-1024 text__40-md text__40-mm ">
                  Excellent Service
                </h1>
                <p className="normal font__size--16 text__16-1024  color__gray-2 mb-4">
                  Treeland's vision is to be the best partner for you and is
                  committed to meeting customers' needs and providing
                  high-quality pre-sales and after-sales services. If you want
                  to know more about Treeland or want to cooperate, please feel
                  free to contact our customer service team.
                </p>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 mb-5 text-center text-lg-left my-lg-auto">
                <img src="./../images/content-3.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Index;
