import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import Pricing from "./homepage/Pricing";
import Blog from "./homepage/Blog";
import About from "./homepage/About";
import Faq from "./homepage/Faq";

// auth
// import Login from "./Auth/Jobs";
import Login from "./Auth/Login";
import Signup from "./Auth/SIgnup";
import Jobs from "./Auth/Jobs";
import ResetPassword from "./Auth/ResetPassword";
import Verify from "./Auth/Verify";
import NewPassword from "./Auth/NewPassword";
import DetailBlog from "./homepage/DetailBlog";
import PrivacyPolicy from "./homepage/PrivacyPolicy";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/detail-blog">
            <DetailBlog />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          {/*
          <Route exact path="/pricing">
            <Pricing />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/detail-blog">
            <DetailBlog />
          </Route>
          <Route exact path="/about-us">
            <About />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/sign-up">
            <Signup />
          </Route>
          <Route exact path="/select-job">
            <Jobs />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/verify">
            <Verify />
          </Route>
          <Route exact path="/new-password">
            <NewPassword />
          </Route>
          */}
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Index;
