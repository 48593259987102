import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
const DetailBlog = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <Navbar type="light" />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-4 mb-md-0">
                <h2 className="bold font__size--40 text__40-1024 text__40-md text__40-mm mb-3">
                  Privacy Policy
                </h2>
                <div className="d-flex align-items-center">
                  <p className="semi-bold font__size--14 text__14-1024 color__gray-2 ml-3 mb-0">
                    Mar 18, 2023
                  </p>
                </div>

                <div className="mt-4">
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    This Privacy Policy outlines how Treeland Inc. (”Treeland”,
                    "we", “us” or "our", as appropriate) collects, uses, and
                    shares information when you use our Shopify app (the
                    "Service"). This Privacy Policy will help you better
                    understand how we collect, use, and share your personal
                    information. We may need to update this Privacy Policy from
                    time to time.You are advised to review this Privacy Policy
                    periodically for any changes. Changes to this Privacy Policy
                    are effective when they are posted on this page.
                  </p>

                  <h4 className="bold font__size--20 text__18-1024 mb-4">
                    Information Collection
                  </h4>
                  <h5 className="bold font__size--18 text__18-1024 mb-4">
                    Personal Data
                  </h5>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    When you install or purchase a product of Treeland, as part
                    of the installing or buying process, we collect the personal
                    information from your store provided by Shopify, including:
                    <ul>
                      <li>
                        First name and last name, Email address, Phone number
                      </li>
                      <li>Store name, Domain, Shopify Plan, Products</li>
                      <li>
                        Activity status of our product: install, uninstall,
                        upgrade, downgrade
                      </li>
                    </ul>
                  </p>
                  <h5 className="bold font__size--18 text__18-1024 mb-4">
                    Usage Data
                  </h5>

                  <p className="normal font__size--18 text__18-1024 mb-4">
                    We may also collect information how the Service is accessed
                    and used ("Usage Data"). This Usage Data may include
                    information such as your computer's Internet Protocol
                    address (e.g. IP address), browser type, browser version,
                    the pages of our Service that you visit, the time and date
                    of your visit, the time spent on those pages, unique device
                    identifiers and other diagnostic data.
                  </p>

                  <h4 className="bold font__size--20 text__18-1024 mb-4">
                    Use of Data
                  </h4>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    We may use the collected data for the following various
                  </p>
                  <li>To provide and maintain our Apps and Services.</li>
                  <li>
                    To manage our Apps and your log-in accounts in our Apps.
                  </li>
                  <li>To process all related payments.</li>
                  <li>To notify you about changes to our Service.</li>
                  <li>
                    To gather analysis or valuable information to improve our
                    customer support service.
                  </li>
                  <li>
                    To allow you to participate in interactive features of our
                    Service when you choose to do so.
                  </li>
                  <li>
                    To monitor the usage of our Service to detect, prevent, and
                    address technical issues.
                  </li>
                  <li>
                    To send you notification emails about news, special offers,
                    and general information about other goods and services, and
                    events unless you have opted not to receive such
                    information.
                  </li>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    Treeland will let you know why collecting your personal
                    information before or at the time. If we ever want to use
                    your personal information for a purpose that is different
                    from what we told you before, we will always ask for your
                    permission first, unless otherwise permitted or required by
                    law.
                  </p>

                  <h4 className="bold font__size--20 text__18-1024 mb-4">
                    Security
                  </h4>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    The security of your Personal Information is important to
                    us, we are committed to protecting your personal data and
                    have taken steps to ensure that your personal information is
                    treated securely. But remember that no method of
                    transmission over the Internet or method of electronic
                    storage is 100% secure. While we strive to use commercially
                    acceptable means to protect your Personal Data, we cannot
                    guarantee its absolute security.
                  </p>
                  <h4 className="bold font__size--20 text__18-1024 mb-4">
                    Service Providers
                  </h4>

                  <p className="normal font__size--18 text__18-1024 mb-4">
                    We may employ third party companies and individuals to
                    facilitate our Service (“Service Providers”), provide
                    Service on our behalf, perform Service-related services or
                    assist us in analyzing how our Service is used.
                  </p>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                  </p>
                  <h4 className="bold font__size--20 text__18-1024 mb-4">
                    Contact Us
                  </h4>
                  <p className="normal font__size--18 text__18-1024 mb-4">
                    If you have any questions or feedback about this Privacy
                    Policy, please do get in touch with us at
                    <a
                      style={{ marginLeft: 6 }}
                      href="mailto:support@treeland.app"
                    >
                      support@treeland.app
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default DetailBlog;
